import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import { MOBILE } from 'composer-blocks/lib/constants/ResponsiveModes';
import i18n from '../helpers/i18n';

const styles = {
  PoweredByFooter: {
    width: '100%',
    height: ({ responsiveMode }) => responsiveMode === MOBILE ? '6rem' : '3rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: ({ responsiveMode }) => responsiveMode === MOBILE ? 'column' : 'row',
    justifyContent: ({ responsiveMode }) => responsiveMode === MOBILE ? 'center' : 'space-around',
    background: '#2a232b',
    color: 'white',
    fontSize: '12px',
  },
  poweredByWrapper:{
    display: 'flex',
    alignItems: 'center',
    marginLeft: ({ responsiveMode }) => responsiveMode === MOBILE ? '0' : '1rem',
  },
  info:{
    textAlign: 'center',
    '& a':{
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  logoWrapper:{
    width: '110px',
    marginLeft: '1rem'
  },
  logo:{
    width: '100%',
  }
};

class PoweredByFooter extends Component {

  render() {
    const { classes, t } = this.props;
    const eventtiaLogo = 'https://connect.eventtia.com/assets/eventtia-error-screens/logo';
    const baseUrl = 'https://www.eventtia.com/';
    const selectedLocale = (!!i18n && i18n.language) || window.location.pathname.split('/')[1];
    const locale = ['en', 'es', 'fr'].includes(selectedLocale) ? selectedLocale : 'en';
    const homeUrl = `${baseUrl}${locale}`;
    const managementPath = locale === 'es' ? 'software-organizacion-eventos' : (locale === 'fr' ? 'logiciel-de-gestion-devenements' : 'event-management-platform');
    const secondaryUrl = `${baseUrl}${locale}/${managementPath}`;
    return (
      <div className={classes.PoweredByFooter}>
        <div className={classes.poweredByWrapper}>
          Powered by
          <div className={classes.logoWrapper}>
            <a href={homeUrl} target="_blank" rel="noopener">
              <img className={classes.logo} src={eventtiaLogo} alt='eventtiaLogo'></img>
            </a>
          </div>
        </div>
        <div className={classes.info}>
          <a href={secondaryUrl} target="_blank" rel="noopener">{t('event_management_software')}</a>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(translate('Trans')(PoweredByFooter));
