import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { fetchComposerSettingsIfNeeded } from "../actions/app";
import { buildFontFaces } from 'composer-blocks/lib/helpers/misc'
import Viewer from '../containers/Viewer';
import LiveLogin from '../containers/LiveLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotPublishedSite from '../components/NotPublishedSite';
import { defaultGlobalFont } from '../helpers/fonts';

const styles = (theme) => ({
  eventLoader: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    color: 'black'
  },
  errorMessage: {
    borderRadius: '0.5rem',
    background: '#ff9999',
    color: '#550000',
  },
  Live: {
    fontFamily: defaultGlobalFont,
  }
});

class Live extends Component {


  constructor(props){
    super(props);
    this.state = {
      loggedIn: false
    };
  }


  componentWillMount() {
    const { fetchComposerSettingsIfNeeded, match } = this.props;
    const { event_uri, page } = match.params
    fetchComposerSettingsIfNeeded(event_uri, page);
  }

  componentDidUpdate(prevProps, prevState) {
    const { events, eventFetchingData, faviconUrl, match } = this.props;

    if (prevProps.eventFetchingData.isFetching && !eventFetchingData.isFetching) {
      const event = events[Object.keys(events)[0]];
      const { locale } = match.params;

      const manifest = {
        "short_name": event.name,
        "name": event.name,
        "start_url": `/${locale}/${event.eventUri}`,
        "icons": [{
          "src": faviconUrl || '/default-logo.png',
          "type": "image/png",
          "sizes": "192x192 512x512"
        }],
        "display": "browser"
      }

      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('link[rel=manifest]').setAttribute('href', manifestURL);
    }
  }

  isLoggedIn = () => {
    // l_p means live password
    const lp = sessionStorage.getItem("l_p");
    return this.state.loggedIn || (!!lp && lp === this.props.sitePassword)
  }

  renderNotPublishedSite = () => {
    const { events } = this.props;
    const { whiteLabelImages: { logo, background, logoFilename, backgroundFilename }, whiteLabel } = events[Object.keys(events)[0]];
    const whiteLabelLogo = !!logoFilename && whiteLabel ? logo : 'https://connect.eventtia.com/assets/eventtia-error-screens/logo';
    const whiteLabelBackground = !!backgroundFilename && whiteLabel ? `url('${background}')` : '#552855';
    return (
      <NotPublishedSite logo={whiteLabelLogo} background={whiteLabelBackground}/>
    )
  }

  render() {

    const { classes, events, eventFetchingData, globalFontId, accountFonts, blocks, sitePassword, loginBackgroundColor } = this.props;
    let loginBackgroundColorString;
    if(!!loginBackgroundColor){
      const { r, g, b, a } = loginBackgroundColor;
      loginBackgroundColorString = `rgba(${r},${g},${b},${a})`;
    }
    return events ? ( (!blocks || !Object.keys(blocks).length) ? (this.renderNotPublishedSite()
    ) : (
      <div className={classes.Live}>
        {!!globalFontId && globalFontId !== '0' && globalFontId.split('-').map((fontId) => {
          if (!accountFonts[fontId]) return null;
          return (
            <div
              key={fontId}
              dangerouslySetInnerHTML={{ __html: buildFontFaces(fontId, accountFonts) }}
            />
          );
        })}
        {!!sitePassword && !this.isLoggedIn() ? (
          <LiveLogin
            showSite={() => this.setState({loggedIn: true})}
            sitePassword={sitePassword}
            backgroundColor={loginBackgroundColorString || "rgba(85, 40, 85, 1)"}/>
        ) : (
          <Viewer />
        )}

      </div>
    )
    ) : (
      <div className={classes.eventLoader}>
        {(eventFetchingData.isFetching) && (
          <CircularProgress className={classes.progress} size={50}/>
        )}

        {(eventFetchingData.error) && (
          <h1 className={classes.errorMessage}>
            {eventFetchingData.error}
          </h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ site, pages, globalSettings, blocks }) => ({
  eventFetchingData: site.pagination.events,
  events: site.entities.events,
  faviconUrl: globalSettings.faviconUrl,
  globalFontId: globalSettings.globalFontId,
  accountFonts: site.entities.account_fonts,
  loginBackgroundColor: globalSettings.loginBackgroundColor,
  sitePassword: globalSettings.sitePassword,
  blocks: blocks,
});

const mapDispatchToProps = {
  fetchComposerSettingsIfNeeded
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
);

export default enhance(Live);
