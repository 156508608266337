export default {
  en: {
    not_published_site: "We're sorry, the website you're looking for is not published",
    log_in: "Log in",
    password: "Password",
    empty_password: "Password can not be empty",
    incorrect_password: "Incorrect password.",
    event_management_software: 'Event Management Software',
    age_gate_continue: "Continue",
    day: "Day",
    month: "Month",
    year: "Year",
  },
  es: {
    not_published_site:
      "Lo sentimos, el sitio web que buscas no está publicado",
    log_in: "Iniciar sesión",
    password: "Contraseña",
    empty_password: "La contraseña no puede estar vacía",
    incorrect_password: "Contraseña incorrecta.",
    event_management_software: "Software de Gestión de Eventos",
    age_gate_continue: "Continuar",
    day: "Día",
    month: "Mes",
    year: "Año",
  },
  fr: {
    not_published_site:
      "Désolé, le site que vous recherchez n'est pas publié.",
    log_in: "Se connecter",
    password: "Mot de passe",
    empty_password: "Le mot de passe ne peut pas être vide",
    incorrect_password: "Mot de passe incorrect.",
    event_management_software: "Logiciel de gestion d'événements",
    age_gate_continue: "Continuer",
    day: "Jour",
    month: "Mois",
    year: "Année",
  },
  de: {
    not_published_site:
      "Es tut uns leid, die gesuchte Website ist nicht veröffentlicht.",
    log_in: "Einloggen",
    password: "Passwort",
    empty_password: "Das Passwort darf nicht leer sein",
    incorrect_password: "Falsches Passwort.",
    event_management_software: "Event Management Software",
    age_gate_continue: "Fortfahren",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
  },
  it: {
    not_published_site:
      "Ci dispiace, il sito che stai cercando non è pubblicato",
    log_in: "Accedi",
    password: "Password",
    empty_password: "La password non può essere vuota",
    incorrect_password: "Password errata.",
    event_management_software: "Event Management Software",
    age_gate_continue: "Continua",
    day: "Giorno",
    month: "Mese",
    year: "Anno",
  },
  pt: {
    not_published_site:
      "Desculpe, o site que você está procurando não está publicado",
    log_in: "Entrar",
    password: "Senha",
    empty_password: "A senha não pode estar vazia",
    incorrect_password: "Senha incorreta.",
    event_management_software: "Software de Gerenciamento de Eventos",
    age_gate_continue: "Continuar",
    day: "Dia",
    month: "Mês",
    year: "Ano",
  },
  ca: {
    not_published_site: "Ho sentim, el lloc web que busques no està publicat",
    log_in: "Iniciar sessió",
    password: "Contrasenya",
    empty_password: "La contrasenya no pot estar buida",
    incorrect_password: "Contrasenya incorrecta.",
    event_management_software: "Programari de gestió d'esdeveniments",
    age_gate_continue: "Continuar",
    day: "Dia",
    month: "Mes",
    year: "Any",
  },
  nl: {
    not_published_site:
      "Het spijt ons, de website waar je naar op zoek bent is niet gepubliceerd",
    log_in: "Inloggen",
    password: "Wachtwoord",
    empty_password: "Wachtwoord mag niet leeg zijn",
    incorrect_password: "Onjuist wachtwoord.",
    event_management_software: "Event Management Software",
    age_gate_continue: "Doorgaan",
    day: "Dag",
    month: "Maand",
    year: "Jaar",
  },
  ar: {
    not_published_site: "نعتذر، الموقع الذي تبحث عنه غير منشور",
    log_in: "تسجيل الدخول",
    password: "كلمة المرور",
    empty_password: "لا يمكن أن تكون كلمة المرور فارغة",
    incorrect_password: "كلمة المرور غير صحيحة.",
    event_management_software: "برنامج إدارة الفعاليات",
    age_gate_continue: "استمر",
    day: "يوم",
    month: "شهر",
    year: "سنة",
  },
  vi: {
    not_published_site:
      "Xin lỗi, trang web bạn đang tìm kiếm chưa được công bố",
    log_in: "Đăng nhập",
    password: "Mật khẩu",
    empty_password: "Mật khẩu không thể để trống",
    incorrect_password: "Mật khẩu không chính xác.",
    event_management_software: "Phần mềm quản lý sự kiện",
    age_gate_continue: "Tiếp tục",
    day: "Ngày",
    month: "Tháng",
    year: "Năm",
  },
  zh: {
    not_published_site: "抱歉，您要查找的网站尚未发布",
    log_in: "登录",
    password: "密码",
    empty_password: "密码不能为空",
    incorrect_password: "密码错误。",
    event_management_software: "活动管理软件",
    age_gate_continue: "继续",
    day: "日",
    month: "月",
    year: "年",
  },
  "zh-TW": {
    not_published_site: "抱歉，您要查詢的網站尚未發布",
    log_in: "登入",
    password: "密碼",
    empty_password: "密碼不能為空",
    incorrect_password: "密碼不正確。",
    event_management_software: "活動管理軟體",
    age_gate_continue: "繼續",
    day: "日",
    month: "月",
    year: "年",
  },
  ko: {
    not_published_site:
      "죄송합니다, 찾고 있는 웹사이트는 공개되지 않았습니다.",
    log_in: "로그인",
    password: "비밀번호",
    empty_password: "비밀번호는 비어 있을 수 없습니다.",
    incorrect_password: "잘못된 비밀번호입니다.",
    event_management_software: "이벤트 관리 소프트웨어",
    age_gate_continue: "계속",
    day: "일",
    month: "월",
    year: "년",
  },
  ja: {
    not_published_site:
      "申し訳ありませんが、お探しのウェブサイトは公開されていません",
    log_in: "ログイン",
    password: "パスワード",
    empty_password: "パスワードは空にできません",
    incorrect_password: "パスワードが正しくありません。",
    event_management_software: "イベント管理ソフトウェア",
    age_gate_continue: "続ける",
    day: "日",
    month: "月",
    year: "年",
  },
};
