import React, { Component } from 'react';
import i18n from './helpers/i18n';
import Live from './pages/Live';
import { Switch, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ca';
import 'moment/locale/nl';
import 'moment/locale/ar';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/ko';
import 'moment/locale/ja';

class App extends Component {
  componentWillMount() {
    i18n.changeLanguage(this.props.match.params.locale);
    moment.locale(this.props.match.params.locale);
  }

  componentWillReceiveProps(nextProps) {
    const old_locale = this.props.match.params.locale;
    const new_locale = nextProps.match.params.locale;

    if (old_locale !== new_locale) {
      i18n.changeLanguage(new_locale);
      moment.locale(new_locale);
    }
  }

  render() {
    const { match } = this.props;

    return (
      <div className="App">
        <Switch>
          <Route path={`${match.path}/:event_uri/:page?`} component={Live}/>
        </Switch>
      </div>
    );
  }
}

export default App;
