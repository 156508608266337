import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './helpers/i18n';
import configureStore from './store/configureStore';
import App from './App';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { createMuiTheme, MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import { BrowserRouter, Route } from 'react-router-dom';
import urls from 'composer-blocks/lib/constants/urls';
import registerServiceWorker, { unregister } from './registerServiceWorker';

const generateClassName = createGenerateClassName({
  productionPrefix: 'live',
});

urls.setEventtiaBase(process.env.REACT_APP_BASE_URL);
const store = configureStore();
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { main: '#000' },
    secondary: { main: '#333' },
  }
});

ReactDOM.render(
  <Provider store={store}>
    <JssProvider generateClassName={generateClassName}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <div>
              <Route path="/:locale(en|es|fr|it|pt|de|ca|nl|ar|vi|zh|zh-TW|ko|ja)" component={App}/>
            </div>
          </MuiThemeProvider>
        </BrowserRouter>
      </I18nextProvider>
    </JssProvider>
  </Provider>,
  document.getElementById('root')
);
// registerServiceWorker();
unregister();
